import moment from 'moment';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const formatCurrency = (amount: number, decimalPlace = 2) => {
  const amountNotUndefined = Number(amount) || 0;
  const amountInString = `${amountNotUndefined.toFixed(decimalPlace)}`;
  return amountInString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function formatToCurrency(
  number: number | string,
  currency = 'NGN',
  style = 'currency',
  minimumFractionDigits = 2
) {
  return (number || 0)?.toLocaleString(currency === 'NGN' ? 'en-NG' : 'en-US', {
    style,
    currency,
    minimumFractionDigits,
  });
}

export const handleTotal = (amount: number) => {
  return amount > 0 ? amount : 0;
};

export const getFileFormat = (path: string) => {
  const fileDetails = path.split('.');
  return fileDetails[fileDetails.length - 1];
};

export const RequestStatus = (status: string): string => {
  const statusObject: Record<string, string> = {
    NEW: 'Pending',
    APPROVED: 'Pending',
    PAID: 'Paid',
    LOGISTICS_FAILED: 'Pending',
    LOGISTICS_ACCEPTED: 'Logistics Notified',
    DEVICE_PICKED_UP: 'In Transit',
    DEVICE_DELIVERED_SC: 'Repair Center',
    REPAIR_IN_PROGRESS: 'Under Repairs',
    REPAIR_COMPLETE: 'Repair Complete',
    DROPOFF_SCHEDULED: 'Ready Delivery',
    DEVICE_PICKED_UP_SC: 'In Transit',
    DEVICE_DELIVERED_USER: 'Completed',
    ORDER_CANCELLED: 'Cancelled',
  };

  return statusObject[status] || 'Unknown';

  // return status;
};

export const RequestStatusColor = (status: string): string => {
  const statusObject: Record<string, string> = {
    NEW: 'red',
    APPROVED: 'red',
    PAID: 'blue',
    LOGISTICS_ACCEPTED: '#8B8000',
    DEVICE_PICKED_UP: '#8B8000',
    DEVICE_DELIVERED_SC: '#8B8000',
    REPAIR_IN_PROGRESS: '#8B8000',
    REPAIR_COMPLETE: 'blue',
    DROPOFF_SCHEDULED: '#8B8000',
    DEVICE_PICKED_UP_SC: '#8B8000',
    DEVICE_DELIVERED_USER: 'green',
    ORDER_CANCELLED: 'red',
  };

  return statusObject[status] || 'red';
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function formatFileSize(totalBytes: number) {
  if (totalBytes < 1000000) {
    return Math.floor(totalBytes / 1000) + 'KB';
  }

  return Math.floor(totalBytes / 1000000) + 'MB';
}

export function xhrRequest({
  method,
  url,
  data,
  onProgress,
  onReadyStateChange,
}: // onCancel,
{
  method: string;
  url: string;
  data: any;
  onProgress: (e: unknown) => void;
  onReadyStateChange: (e: unknown) => unknown;
}) {
  const xhr = new XMLHttpRequest();

  xhr.open(method, url, true);

  xhr.upload.onprogress = onProgress;

  xhr.onreadystatechange = onReadyStateChange;

  // xhr.upload.onabort = onCancel;

  xhr.send(data);
  return xhr;
}

export const KYC_TYPES = Object.freeze({
  '': {
    label: 'Select ID',
    value: '',
    isVisible: true,
  },
  VNIN: {
    label: 'Virtual National Identification Number (VNIN)',
    value: 'VNIN',
    isVisible: true,
  },
  VIN: {
    label: 'Voters Card Identification Number (VIN)',
    value: 'VIN',
    isVisible: true,
  },
  PASSPORT: {
    label: 'International Passport',
    value: 'PASSPORT',
    isVisible: false,
  },
  DRIVERLICENCE: {
    label: "Driver's Licence",
    value: 'DRIVERLICENCE',
    isVisible: false,
  },
});

export const GENDER_TYPES = Object.freeze({
  '': {
    label: 'Select gender',
    value: '',
  },
  MALE: {
    label: 'Male',
    value: 'MALE',
  },
  FEMALE: {
    label: 'Female',
    value: 'FEMALE',
  },
});

export const getErrorMessage = (error: any): string => {
  if (error?.response) {
    /* The request was made
    the server responded with a status code that falls out of range of 2xx */
    return (
      error.response.data.message ||
      error.response.data.error ||
      error.response.data.detail ||
      'Something went wrong'
    );
  }

  if (error?.data) {
    return error.data.message;
  }

  if (error?.message) {
    // Something happened in setting up the request that triggered an error
    return error.message;
  }

  if (error?.request) {
    // The request was made but no response was received
    return 'Something went wrong';
  }
  return '';
};

export const planStatus = (item: string, hasExpired: boolean) => {
  switch (item) {
    case 'PENDING':
      return { text: 'Pending', color: 'yellow.500' };
    case 'ONBOARDED':
      if (hasExpired) {
        return { text: 'Expired', color: 'red' };
      }
      return { text: 'Onboard', color: 'green' };
    case 'PAID':
      if (hasExpired) {
        return { text: 'Expired', color: 'red' };
      }
      return { text: 'Paid', color: 'blue' };
    default:
      return { text: 'Pending', color: 'yellow.500' };
  }
};

export const careStatus = (item: {
  onboarding_completed: boolean;
  onboarding_approved: boolean;
  onboarding_cancelled: boolean;
}) => {
  if (item.onboarding_approved) {
    return { text: 'Approved', color: 'green' };
  } else if (item?.onboarding_cancelled) {
    return { text: 'Cancelled', color: 'red' };
  } else if (item?.onboarding_completed) {
    return { text: 'Completed', color: 'blue' };
  } else return { text: 'Pending', color: 'yellow.500' };
};

export interface ValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export const validateEmail = (email: string): ValidationResult => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return {
      isValid: false,
      errorMessage: 'Email is required',
    };
  }
  if (!emailRegex.test(email)) {
    return {
      isValid: false,
      errorMessage: 'Please enter a valid email address',
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};

export type RenewalType = 'yearly' | 'monthly';

export const computeTimePeriods = (
  renewalDate: string,
  type: RenewalType
): number[] => {
  const currentDate = new Date(); // Using the provided current time
  const renewal = new Date(renewalDate);
  const periods: number[] = [];

  if (type === 'yearly') {
    const startYear = renewal.getFullYear();
    const currentYear = currentDate.getFullYear();
    const endYear = currentYear + 5; // Adding 5 extra years

    for (let year = startYear; year <= endYear; year++) {
      periods.push(year);
    }
  } else {
    // For monthly calculation
    const monthDiff =
      (currentDate.getFullYear() - renewal.getFullYear()) * 12 +
      (currentDate.getMonth() - renewal.getMonth());

    // Generate array from 1 to (monthDiff + 5)
    for (let i = 1; i <= monthDiff + 5; i++) {
      periods.push(i);
    }
  }

  return periods;
};

export const calculateMonthsOwed = (
  renewalDate: string | Date,
  hasExpired: boolean
): number => {
  if (!hasExpired) return 0;
  if (!renewalDate) return 0;

  const renewal = moment(renewalDate);
  const now = moment();

  // Get the exact number of months between dates
  const months = now.diff(renewal, 'months');

  // Check if there are additional days that would round up to another month
  const remainingDays = now.diff(renewal.add(months, 'months'), 'days');

  // Round up if there are any remaining days
  return months + (remainingDays > 0 ? 1 : 0);
};

export const calculateAmountDue = (
  renewalDate: string | Date,
  hasExpired: boolean,
  monthlyPrice: number
): number => {
  const monthsOwed = calculateMonthsOwed(renewalDate, hasExpired);
  return monthsOwed * monthlyPrice;
};

export const calculateTotalDue = (plans: any): number => {
  return plans.reduce((total: number, plan: any) => {
    const amountDue = calculateAmountDue(
      plan.renewal_date,
      plan.hasExpired,
      Number(plan.insurance_plan?.price || 0)
    );
    return total + amountDue;
  }, 0);
};
